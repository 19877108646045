.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 500px;
  background: url("../assets/img/home-bg.webp") no-repeat center;
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -200px;
  width: 340px;
}

.banner-inner h1 {
  font-size: 2.3em;
}

.banner-inner p {
  margin-top: 8px;
  font-size: 1em;
  line-height: 2em;
}

.banner-inner .buy-now {
  background-color: black;
  border: none;
  color: white;
  margin-top: 10px;
  padding: 15px 24px;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 0.5em;
  border-radius: 4px;
  position: absolute;
}

.banner-inner .buy-now:hover {
  color: rgb(214, 214, 214);
  background-color: rgb(34, 34, 34);
  transition: 100ms ease;
}

.main-section-container {
  margin: 30px 0;
}

.detail {
  display: grid;
  grid-template-columns: 3fr 3fr;
  margin: 50px 0;
}

.img {
  width: 100%;
  height: 600px;
  opacity: 0.85;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.5);
}

.img-real {
  width: 100%;
  height: 600px;
  opacity: 0.85;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.5);
}

.img-real-content {
  width: 100%;
  height: 100%;
}

.img-container-left {
  margin-right: 30px;
}

.img-container-right {
  margin-left: 30px;
}

.desc {
  margin: auto 40px;
  margin-top: 80px;
  display: grid;
  grid: 1fr;
  row-gap: 20px;
  position: relative;
}

.desc p {
  line-height: 1.8em;
  text-align: justify;
  margin-bottom: 20px;
  font-size: 1.2em;
}

@media (max-width: 900px) {
  .banner-image {
    background-position-x: calc(60vw - 1020px);
    background-position-y: -40px;
    transition-property: 200ms ease;
    height: 450px;
    margin-bottom: -40px;
  }

  .banner-inner {
    position: unset;
    transform: unset;
    margin: 10px auto 10px auto;
    width: 280px;
    text-align: center;
  }

  .banner-inner h1 {
    font-size: 2em;
  }

  .banner-inner p {
    font-size: 0.9em;
    margin-bottom: 0;
  }

  .banner-inner .buy-now {
    margin-left: -80px;
    font-size: 0.86em;
  }

  .detail {
    grid-template-columns: 1fr;
    justify-content: center;
    align-content: center;
  }

  .detail h1 {
    font-size: 1.5em;
  }

  .detail p {
    font-size: 1.1em;
  }

  .img,
  .img-real {
    background-position-x: center;
    height: 450px;
    width: calc(100vw - 60px);
    margin: 0 30px;
    overflow: hidden;
    background-size: 100%;
    grid-row-start: 1;
  }

  .img-container-left {
    margin-right: 0;
  }

  .img-container-right {
    margin-left: 0;
  }

  .desc {
    margin-top: 20px;
    grid-row-start: 2;
  }

  .desc p,
  .desc h1 {
    text-align: center;
  }

  .desc button {
    position: initial;
  }
  .banner-inner {
    height: 200px;
  }

  .banner-inner button {
    position: absolute;
    transform: translate(-50%, 0);
    width: 92vw;
    transition-property: width;
    transition-duration: 200ms;
  }

  .desc button {
    transition-property: width;
    transition-duration: 200ms;
  }
}

@media (max-width: 600px) {
  .banner-image {
    height: 300px;
    background-size: cover;
    background-position-x: calc(60vw - 650px);
    transition: none;
  }

  .img,
  .img-real {
    min-height: 50px;
    height: calc(100vw - 100px);
  }

  .cart-small {
    width: 60px;
  }
}
