.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  background-size: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  margin: 30px 0;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
}

.main-section {
  grid-column-start: 2;
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.profile-menu {
  margin: 20px 0 0 0;
  border-right: 1px solid black;
  display: flex;
  flex-direction: column;
}

.profile-menu p {
  list-style-type: none;
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 1.2em;
  cursor: pointer;
  color: #3c6373;
  border-bottom: 3px solid rgb(0, 0, 0, 0);
}

.profile-menu p:hover {
  border-bottom: 3px solid #3c6373;
  transition: border-bottom 200ms ease;
}

.profile-content {
  margin: 20px;
}

.no-orders {
  text-align: center;
  font-weight: 500;
  font-size: 1.3em;
}

@media (max-width: 1300px) {
  .main-section-container {
    grid-template-columns: 1fr 10fr 1fr;
    padding: 0 10px;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section-container {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .main-section-container {
    grid-template-columns: 1fr;
    padding: 0 30px;
  }

  .main-section {
    grid-column-start: 1;
  }

  .banner-inner,
  .spacer {
    width: 92vw;
  }

  .main-section p {
    font-size: 1em;
  }

  .profile-content {
    margin-right: 0;
  }
}

@media (max-width: 600px) {
  .main-section-container {
    padding: 0 20px;
  }

  .profile-menu p {
    font-size: 0.8em;
  }
}

@media (max-width: 500px) {
  .profile-menu p {
    font-size: 0.6em;
  }
}

@media (max-width: 400px) {
  .main-section {
    width: 100%;
    font-size: 0.9em;
    justify-self: center;
  }

  .main-section-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
}
