.same-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.middle-column input {
  width: 85%;
}

.input,
.same-row .input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 10px;
}

.captcha {
  display: flex;
  justify-content: center;
}

@media (max-width: 400px) {
  .captcha iframe {
    transform: scale(0.85) !important;
  }

  .same-row .middle-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .middle-column input {
    width: unset;
  }

  .same-row {
    grid-template-columns: 1fr;
  }
}
