.reset-password-container {
  grid-column-start: 2;
  height: 100%;
}

.reset-password {
  height: 100%;
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-items: center;
}

.reset-password-form {
  grid-column-start: 2;
  padding: 30px;
  border: 1px solid black;
  border-radius: 4px;
  width: 80%;
}

.reset-password-form form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.reset-password-form h2 {
  text-align: center;
}

.reset-password-form form button {
  margin: 14px 0 0 0;
}

.control {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px auto;
}

.control label {
  font-size: 1.1em;
  font-weight: 500;
}

.control input {
  padding: 10px;
  margin: 4px 10px 4px 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  resize: none;
}

.go-back {
  font-size: 0.9em;
  margin-top: 10px;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  text-align: center;
}
