.checkout-container {
  min-height: 200px;
  min-width: 200px;
  height: unset;
}

.close {
  background-color: unset;
  border: none;
  color: rgb(155, 155, 155);
  padding: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 8px;
}

.checkout {
  height: 100%;
  margin: 20px 20px;
  color: rgb(0, 158, 227);
}

.checkout h1 {
  margin: 20px 0;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2rem;
  text-align: center;
}

.form-payment {
  border-top: 2px solid #c6e9fa;
  box-shadow: 0px 2px 10px rgb(0 0 0 / 8%);
  background-color: #ffffff;
  padding: 0;
  max-width: 600px;
  margin: auto;
}

.form-payment h2 {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-size: 1.3em;
}

.products {
  padding: 30px;
}

.products p,
.total {
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
}

.spacer {
  height: 1px;
  background-color: #dbdbdb;
  margin: 6px 0;
}

.item,
.total {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.price {
  font-weight: 400;
}

#button-checkout {
  justify-self: center;
  text-align: center;
}

.loading {
  text-align: center;
}

.go-back {
  margin-top: 20px;
  cursor: pointer;
}

@media (max-width: 450px) {
  .checkout-container {
    width: 80%;
  }

  .checkout h1 {
    font-size: 1.5em;
  }
}
