.main-section-container {
  display: grid;
  grid-template-columns: 1fr 20fr 1fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
}

.main-section h2 {
  margin-bottom: 20px;
}

.main-section label {
  font-size: 1.1em;
  font-weight: 500;
}

.main-section input,
.main-section textarea {
  padding: 10px;
  margin: 10px;
  display: block;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.input,
.same-row .input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.same-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.middle-column input {
  width: 85%;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 10px;
  text-align: center;
}

@media (max-width: 900px) {
  .main-section-container {
    display: unset;
  }
}

@media (max-width: 600px) {
  .same-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: unset;
  }

  .middle-column input {
    width: 85%;
  }

  .same-row .middle-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .middle-column input {
    width: unset;
  }

  .same-row {
    grid-template-columns: 1fr;
  }
}
