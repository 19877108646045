.user-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.middle-title {
  margin-bottom: 20px;
  margin-top: 10px;
}

.middle-title h2 {
  margin-bottom: 0;
}

.middle-title p {
  font-size: 0.9em;
}

.input,
.same-row .input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.same-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.middle-column input {
  width: 85%;
}

.dropdown {
  display: flex;
  padding: 6px;
  font-size: 1.1em;
  font-weight: 500;
  margin: 10px;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  resize: none;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 10px;
}

@media (max-width: 400px) {
  .same-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: unset;
  }

  .middle-column input {
    width: 85%;
  }

  .same-row .middle-column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .middle-column input {
    width: unset;
  }

  .same-row {
    grid-template-columns: 1fr;
  }
}
