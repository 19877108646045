.footer-container {
  color: rgb(182, 182, 182);
  background-color: black;
  height: 340px;
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-container h4 {
  margin-bottom: 30px;
}

.footer-container a {
  color: rgb(182, 182, 182);
}

.footer-main {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 4fr 3fr 1fr 3fr 1fr 3fr 4fr;
  grid-template-areas: ". logo . site . news .";
}

.footer-col1 {
  grid-area: logo;
}

.footer-col2 {
  grid-area: site;
}

.footer-col3 {
  grid-area: news;
}

.footer-col1 p,
.footer-col3 p {
  margin-left: 4px;
  margin-top: 10px;
  line-height: 1.6em;
}

.footer-col2,
.footer-col3 {
  margin-top: 20px;
  padding-left: 10px;
}

.logo {
  height: 80px;
}

.links {
  margin-top: 15px;
}

/* .footer-col3 input,
.footer-col3 input:focus-visible {
  width: 200px;
  background-color: black;
  color: rgb(156, 156, 156);
  border: none;
  outline: none;
  padding-bottom: 6px;
  border-bottom: 1px solid rgb(233, 233, 233);
  font-size: 1.2em;
} */

.copyright {
  grid-area: copy;
  border-top: 1px solid rgb(202, 202, 202, 0.2);
  margin-top: auto;
  margin-bottom: 8px;
  padding: 12px 200px 0 200px;
}

@media (max-width: 900px) {
  .footer-container {
    height: 100%;
  }

  .footer-main {
    position: relative;
    margin-top: 10px;
    display: block;
    margin-left: 20px;
    margin-right: auto;
  }

  .footer-col1 {
    margin-top: 30px;
  }

  .footer-col1,
  .footer-col2,
  .footer-col3 {
    width: 100%;
    margin-bottom: 40px;
  }

  .footer-col1 p,
  .footer-col3 p {
    width: 300px;
  }

  .copyright {
    padding: 12px 120px 0 120px;
  }
}

@media (max-width: 600px) {
  .copyright {
    padding: 12px 30px 0 30px;
    font-size: 0.8em;
  }
  .footer-main {
    font-size: 0.85em;
  }

  .footer-col1 p,
  .footer-col3 p {
    width: 250px;
  }
}
