.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  overflow: auto;
  width: 500px;
}

.modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: flex-start;
}

.order-content {
  margin-left: 20px;
}

.modal-content {
  width: 100%;
}

.modal-content h2 {
  text-align: center;
  margin: 20px 0;
}

.modal-content p,
.modal-content li {
  margin: 10px 0;
}

.modal-content li {
  margin-left: -10px;
}

.close {
  background-color: unset;
  border: none;
  color: rgb(155, 155, 155);
  padding: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 8px;
}

.empty-cart {
  margin: 20px 0;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  align-self: center;
}

.buttons button {
  margin: 10px 10px;
}

@media (max-width: 900px) {
  .modal-container {
    width: 70vw;
  }

  .modal-content {
    font-size: 1em;
  }
}

@media (max-width: 700px) {
  .modal-container {
    width: 75vw;
  }

  .modal-content {
    font-size: 0.9em;
  }
}

@media (max-width: 550px) {
  .modal-container {
    width: 80vw;
  }

  .modal-content {
    font-size: 0.8em;
  }
}

@media (max-width: 400px) {
  .modal-container {
    width: 85vw;
  }

  .modal-content {
    font-size: 0.7em;
  }
}
