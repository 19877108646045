.backdrop {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  background: rgba(0, 0, 0, 0.25);

  right: -100vw;
  opacity: 0;

  transition-duration: 300ms, 0ms;
  transition-property: opacity, right;
  transition-delay: 0ms, 300ms;
}

.show {
  right: 0;
  opacity: 1;

  transition-duration: 0ms, 300ms;
  transition-property: right, opacity;
  transition-delay: 0ms, 0ms;
}

/* @media (min-width: 900px) {
  .backdrop {
    display: none;
  }
} */
