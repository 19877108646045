* {
  margin: 0;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
}

body,
#root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}
