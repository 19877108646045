.auth-form-container {
  width: 90%;
  grid-column-start: 2;
  height: 100%;
}

.auth-form-container p {
  margin-top: 20px;
  text-align: center;
}

.auth-mode {
  color: blue;
  cursor: pointer;
}

.auth-form {
  padding: 30px;
  border: 1px solid black;
  border-radius: 4px;
}

.auth-form form {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.auth-form h2 {
  text-align: center;
}

.auth-form form button {
  margin: 14px 0 0 0;
}

.control {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px auto;
}

.control label {
  font-size: 1.1em;
  font-weight: 500;
}

.control input {
  padding: 10px;
  margin: 4px 10px 4px 0;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
  resize: none;
}

.forgot-password {
  margin-top: 14px;
  font-size: 0.9em;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
}

@media (max-width: 900px) {
  .auth-form-container {
    width: 90%;
    grid-column-start: 1;
  }

  .auth-form form button {
    width: 100%;
  }
}
