.item-container {
  min-height: 100px;
  display: grid;
  grid-template-columns: 5fr 4fr 4fr 4fr;
  align-items: center;
  justify-items: center;
  text-align: center;
}

.item-image {
  height: 100%;
  width: 90% !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.add-substract-buttons button {
  padding: 6px 10px;
  margin: 4px;
  width: 30px;
}

.unit-cost-qty em {
  font-size: 0.9em;
}

@media (max-width: 900px) {
  .item-container {
    grid-template-columns: 5fr 2fr 2fr 2fr;
    background-size: cover;
  }

  .item-image {
    min-height: 130px;
    width: 70% !important;
  }
}

@media (max-width: 650px) {
  .item-container {
    grid-template-columns: 4fr 2fr 1fr;
    grid-template-areas:
      "image title controls"
      "image quantity controls";
  }

  .item-image {
    grid-area: image;
    height: 100%;
    width: 75% !important;
  }

  .product-information {
    grid-area: title;
    align-self: flex-end;
    font-size: 0.9em;
  }

  .unit-cost-qty {
    grid-area: quantity;
    align-self: flex-start;
    font-size: 0.9em;
  }

  .controls {
    grid-area: controls;
  }
}

@media (max-width: 500px) {
  .item-container {
    grid-template-columns: 2fr 2fr 1fr;
  }

  .product-information,
  .unit-cost-qty {
    font-size: 0.8em;
  }
}
