.rating {
  margin: auto 0;
}

.rating svg {
  width: 20px;
  height: 20px;
}
.rating svg * {
  width: 100%;
  height: 100%;
}
