.color-container {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.color-container button {
  margin-right: 10px;
  height: 40px;
  width: 40px;
  background-color: white;
  border: 1px solid rgb(119, 119, 119);
  transform: scale(0.8);
  cursor: pointer;
  /* box-shadow: 0 0 0 3px hsl(0, 0%, 100%), 0 0 0 4px hsl(0, 0%, 0%); */
}

.color-container .selected-color {
  box-shadow: 0 0 0 3px hsl(0, 0%, 100%), 0 0 0 4px hsl(0, 0%, 0%);
}
