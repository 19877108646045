.thumbs {
  justify-content: center;
  display: flex;
  padding: 0;
}

.thumb {
  margin: 2px 20px;
  border-radius: 5px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(94, 160, 187, 0);
  cursor: pointer;
}

.thumb:hover,
.thumb.selected {
  border: 3px solid rgb(0, 0, 0, 0) !important;
  box-shadow: 0 0 0 1px rgba(94, 160, 187, 0.4),
    0 0 0 1px rgba(94, 160, 187, 0.4);
}

@media (max-width: 500px) {
  .carousel-root {
    width: 90vw;
  }

  .thumbs {
    transform: translate3d(0, 0, 0) !important;
  }

  .thumb {
    margin: 2px 10px;
    width: 60px !important;
  }
}
