.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  background-size: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.checkout-content form {
  position: relative;
}

.order-info {
  background-color: rgb(238, 238, 238);
  border-radius: 6px;
  padding: 10px;
  margin: 10px;
  margin-bottom: 20px;
}

.same-row {
  display: flex;
  justify-content: space-between;
}

.same-row input {
  width: 80%;
}

.order-info-header {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.order-info-spacer {
  background-color: rgb(223, 223, 223);
  height: 2px;
  margin: 20px 0;
}

.order-info-items {
  display: flex;
  flex-direction: column;
  font-weight: 400;
}

.order-info-shipping {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.order-info-shipping button {
  padding: 5px 3px;
  margin: 8px 0;
}

.order-info-subtotal,
.order-info-shipping,
.order-info-tax,
.order-info-total,
.order-info-discount {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 2px;
}

.order-info-shipping {
  margin-bottom: 20px;
}

.order-info-discount {
  color: #198754;
}

.empty-cart {
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  align-self: center;
}

.main-section-container {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  padding-top: 20px;
}

.main-section h2 {
  margin-bottom: 20px;
}

.main-section label {
  font-size: 1.1em;
  font-weight: 500;
}

.main-section input,
.main-section textarea {
  padding: 10px;
  margin: 10px;
  display: block;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.input-coupon {
  display: grid;
  grid-template-columns: 7fr 3fr;
}

.input-coupon input {
  height: 16px;
}

.input-coupon input::placeholder {
  font-style: italic;
}

.input-coupon button {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 4px;
}

.main-section textarea {
  height: 80px;
}

.main-section form {
  position: relative;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 10px;
  text-align: center;
}
.warning-coupon {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: center;
}

.success-coupon {
  color: #198754;
  font-weight: 500;
  font-size: 0.8em;
  margin-bottom: 0;
  text-align: center;
}

.iframe-section {
  display: grid;
  grid-template-columns: 1fr;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  padding-top: 20px;
}

.free-shipping {
  background-color: #e9e9e9;
  font-size: 0.85em;
  font-weight: bold;
  color: rgb(99, 99, 99);
  border-radius: 6px;
  border: 2px solid rgb(173, 173, 173);
  padding: 16px;
}

@media (max-width: 1300px) {
  .main-section-container {
    grid-template-columns: 1fr 10fr 1fr;
    padding: 0 10px;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section-container {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .main-section-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .main-section {
    grid-template-columns: 1fr;
    grid-column-start: 1;
  }

  .banner-inner,
  .spacer {
    width: 92vw;
  }

  .main-section p {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .main-section {
    width: 100%;
    font-size: 0.9em;
    justify-self: center;
  }

  .order-info-shipping input {
    width: 60%;
  }
}
