.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 5px;
  height: 40px;
}

.content button {
  margin: 0;
  padding: 5px 10px;
}

/* New styles for the complete footer */
.footer {
  background-color: #f8f9fa;
  padding: 40px 0;
  font-family: "Arial", sans-serif;
}

.footer-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.footer-section h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.footer-section a {
  color: #333;
  text-decoration: none;
  margin-bottom: 5px;
  transition: color 0.3s;
}

.footer-section a:hover {
  color: #007bff;
}

.social-media-icons {
  display: flex;
  justify-content: space-between;
  width: 160px;
}

.social-media-icons a {
  color: #333;
  font-size: 24px;
  transition: color 0.3s;
}

.social-media-icons a:hover {
  color: #007bff;
}

/* End of new styles for the complete footer */

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.modal-transition-on {
  opacity: 1;
  transition: opacity 300ms;
}

.modal-transition-off {
  opacity: 0;
  transition: opacity 200ms;
}

.modal {
  width: 100%;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.modal h1,
.modal h2,
.modal h3 {
  margin: 10px 0;
}

.modal h2 {
  text-align: center;
}

.modal h3 {
  margin-bottom: 20px;
  font-weight: normal;
}

.modal div {
  margin: 10px 0;
  justify-items: center;
}

.modal label {
  font-size: 1.1em;
  font-weight: 500;
}

.modal input {
  padding: 10px;
  margin: 10px;
  display: block;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.subscribe {
  width: 100%;
  background-color: #6a6a6a;
  color: rgb(205, 203, 203);
  font-size: 0.8em;
}

.subscribe:hover {
  background-color: #797979;
}

.close {
  background-color: unset;
  border: none;
  color: rgb(155, 155, 155);
  padding: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 8px;
}

h2 {
  font-size: 24px;
  margin-top: 0;
}

p {
  font-size: 18px;
  margin-bottom: 20px;
}

.input {
  width: 100%;
  font-size: 0.9em;
  margin-right: 10px;
  margin-top: auto;
  margin-bottom: auto;
}

.input input {
  width: 200px;
  background-color: black !important;
  color: rgb(156, 156, 156);
  border: none;
  outline: none;
  padding-bottom: 6px;
  border-bottom: 1px solid rgb(233, 233, 233);
  font-size: 1em;
}

.warning {
  color: rgb(218, 23, 23);
  font-weight: 500;
  font-size: 0.85em;
  margin-bottom: 10px;
  text-align: center;
}

/* Responsive design for smaller screens */
@media (max-width: 900px) {
  .modal-container {
    width: 80%;
  }
  .modal {
    min-width: 100px;
  }

  .content {
    grid-template-columns: 1fr;
  }

  .input input {
    margin-bottom: 10px;
    width: 100%;
  }
}

@media (max-width: 600px) {
  .modal div {
    margin: 0;
  }

  .modal div h1 {
    font-size: 1.5em;
  }

  .modal div h2 {
    font-size: 1.2em;
  }

  .modal div h3 {
    font-size: 1.1em;
  }

  .footer {
    padding: 20px 0;
  }

  .footer-section {
    margin-bottom: 10px;
  }

  .footer-section h4 {
    font-size: 16px;
    margin-bottom: 5px;
  }

  .footer-section a {
    margin-bottom: 3px;
  }

  .social-media-icons {
    width: 140px;
  }

  .social-media-icons a {
    font-size: 20px;
  }
}
