.product-details-text,
.product-details-bullets {
  text-align: justify;
  font-size: 0.85em;
  line-height: 1.4em;
}

.product-details-bullets {
  margin: 6px 0;
  line-height: 1.3em;
  padding-left: 30px;
}

.product-details h4 {
  color: rgb(126, 126, 126);
  margin: 4px 0;
}
