.cart-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.cart-modal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
}

.cart-modal h1 {
  margin: 10px 0;
}

.cart-modal div {
  margin: 10px 0;
  justify-items: center;
}

.close {
  background-color: unset;
  border: none;
  color: rgb(155, 155, 155);
  padding: 4px;
  text-align: center;
  text-decoration: none;
  font-size: 1.2em;
  font-weight: 300;
  cursor: pointer;
  position: absolute;
  top: 2px;
  right: 8px;
}

.empty-cart {
  margin: 20px 0;
  font-weight: 500;
  font-size: 1.2em;
  text-align: center;
  align-self: center;
}

.buttons button {
  margin: 10px 10px;
}

.totals {
  display: flex;
  flex-direction: column;
  justify-items: end;
  text-align: right;
}

.checkout {
  width: 90%;
  margin: 10px;
  margin-top: auto;
  margin-bottom: 20px;
}

.checkout:disabled {
  background-color: grey;
}

.content {
  overflow: auto;
  width: 90%;
  grid: flex;
  justify-content: center;
  align-content: center;
}

@media (max-width: 900px) {
  .cart-modal-container {
    width: 80%;
  }
  .cart-modal {
    min-width: 100px;
  }

  .totals {
    font-size: 0.9em;
    padding-right: 8px;
  }
}

@media (max-width: 500px) {
  .cart-modal div {
    margin: 0;
  }
}
