.main-section-container {
  display: grid;
  grid-template-columns: 1fr 40fr 1fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
}

.main-section h2 {
  margin-bottom: 20px;
}

.main-section label {
  font-size: 1.1em;
  font-weight: 500;
}

.main-section input,
.main-section textarea {
  padding: 10px;
  margin: 10px;
  display: block;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.input,
.same-row .input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.same-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.middle-column input {
  width: 85%;
}

.orders-container {
  display: grid;
  grid-template-columns: 1fr;
}

.orders-header {
  font-weight: 600;
}

.orders-header,
.orders {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  justify-items: center;
}

.orders {
  margin: 10px 0;
}

.order-number {
  font-weight: 600;
}

.order-status {
  margin: 0 20px;
}

.details-button {
  cursor: pointer;
}

.empty-orders {
  font-weight: 500;
  font-size: 1.1em;
  text-align: center;
  align-self: center;
}

@media (max-width: 900px) {
  .orders-header {
    font-size: 0.9em;
  }

  .orders {
    font-size: 0.8em;
  }
}

@media (max-width: 700px) {
  .orders-header {
    font-size: 0.7em;
  }

  .orders {
    font-size: 0.6em;
  }
}

@media (max-width: 600px) {
  .orders-header {
    font-size: 0.6em;
  }

  .orders {
    font-size: 0.4em;
  }
}
