.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  opacity: 0.5;
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  margin: 30px 0;
}

.main-section {
  display: flex;
  flex-direction: column;
}

.product {
  grid-column-start: 2;
}

.product-container {
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
}

.product-spacer {
  height: 1px;
  background-color: #838383;
  margin: 30px 0;
  width: 66vw;
  align-self: center;
}

@media (max-width: 1300px) {
  .main-section {
    grid-template-columns: 1fr 10fr 1fr;
  }

  .product-container {
    grid-template-columns: 1fr 8fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .product-container {
    grid-template-columns: 1fr 12fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .main-section {
    grid-template-columns: 1fr;
  }

  .banner-inner,
  .spacer {
    width: 92vw;
  }
}
