.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  opacity: 0.5;
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  margin-bottom: 20px;
  padding-top: 20px;
}

.main-section p {
  margin: 8px 0;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.3em;
  text-align: justify;
}

.order-content {
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #3c6373;
}

.order-content-header {
  background-color: black;
  padding: 10px;
}

.order-content-info {
  margin: 20px;
  padding: 5px;
  background-color: white;
}

.order-content-info p,
.order-content-info ul {
  font-size: 0.9em;
  margin-left: 10px;
}

.order-content h2 {
  font-size: 1.3em;
  background-color: black;
  color: white;
  width: 100%;
}

@media (max-width: 1300px) {
  .main-section-container {
    grid-template-columns: 1fr 10fr 1fr;
    padding: 0 10px;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section-container {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .main-section-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .banner-inner,
  .spacer {
    width: 92vw;
  }

  .main-section p {
    font-size: 1em;
  }
}
