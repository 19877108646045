.main-section-container {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-section {
  display: grid;
  grid-column-start: 2;
  grid-template-columns: 1fr;
  text-align: center;
}

.talvi-logo {
  width: 350px;
  height: 350px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  justify-self: center;
}

.main-section h1,
.main-section h2,
.main-section h3 {
  margin: 10px 0;
}
