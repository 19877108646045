iframe #add_payment {
    color: red !important;
}

@media (min-width: 992px) {
    #divPageMain.container {
        width: 100%;
    }
}
@media (min-width: 450px) {
    #divPageMain.container {
        width: 100%;
    }

}