.cart-item-container {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  margin: 10px 2px;
}

.add-substract-buttons {
  display: flex;
  font-size: 0.85em;
  margin-top: 4px;
  color: #4c7f92;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.add-substract-buttons span {
  cursor: pointer;
}

.add-substract-buttons span:hover {
  text-decoration: underline;
}

.subtotal {
  display: flex;
  align-self: center;
  justify-self: right;
}
