.button {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #3c6373;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  cursor: pointer;
  border-radius: 4px;
  position: relative;
}

.button:hover {
  background-color: rgb(76, 131, 153);
}

.button:disabled {
  background-color: rgb(117, 117, 117);
}
