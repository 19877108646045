.side-menu-container {
  position: fixed;
  background-color: rgb(41, 41, 41);
  height: 100%;
  width: 65vw;
  z-index: 10;

  right: -100vw;

  transition-duration: 300ms;
  transition-property: right;
}

.show {
  right: 0;

  transition-duration: 300ms;
  transition-property: right;
}

.side-menu-content {
  display: flex;
  justify-content: space-between;
}

.side-menu-links {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.side-menu-links a,
.side-menu-links a:visited {
  color: white;
  margin: 15px 15px;
}

.side-menu-links a:hover,
.active {
  color: rgb(201, 201, 201);
}

.language {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.language div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 30px;
  cursor: pointer;
  margin: 15px;
}

@media (min-width: 900px) {
  .side-menu-container {
    display: none;
  }
}
