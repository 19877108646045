.size-container {
  list-style-type: none;
  padding: 0;
  display: flex;
}

.size-container button {
  margin-right: 10px;
  height: 40px;
  width: 40px;
  background-color: white;
  border: 2px solid black;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
  color: black;
}

.size-container button:hover {
  color: white;
  background-color: black;
  transition: 200ms ease;
}

.size-container .selected-size {
  color: white;
  background-color: black;
}
