.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  background-size: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  padding-top: 20px;
}

.main-section h2 {
  margin-bottom: 20px;
}

.main-section label {
  font-size: 1.1em;
  font-weight: 500;
}

.main-section input,
.main-section textarea {
  padding: 10px;
  margin: 10px;
  display: block;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: none;
}

.main-section textarea {
  height: 80px;
}

.main-section form {
  position: relative;
}

@media (max-width: 1300px) {
  .main-section-container {
    grid-template-columns: 1fr 10fr 1fr;
    padding: 0 10px;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section-container {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .main-section-container {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }

  .main-section {
    grid-column-start: 1;
  }

  .banner-inner,
  .spacer {
    width: 92vw;
  }

  .main-section p {
    font-size: 1em;
  }
}

@media (max-width: 400px) {
  .main-section {
    width: 100%;
    font-size: 0.9em;
    justify-self: center;
  }

  .main-section form {
    width: 90vw;
  }
}
