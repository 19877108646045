.modal {
  position: fixed;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* height: 400px; */
  width: 400px;
  z-index: 10;
  border-radius: 10px;
}
