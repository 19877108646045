.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  background-size: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  margin-bottom: 20px;
  padding-top: 20px;
}

.main-section p {
  margin: 8px 0;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.3em;
  text-align: justify;
}

.no-posts {
  text-align: center;
  font-weight: 500;
  font-size: 1.3em;
}

.post-container {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 16px;
  background-color: #fafafa;
  box-shadow: 0 0 0 3px hsl(0, 0%, 100%), 0 0 0 4px hsl(0, 0%, 80%);
}

.date {
  font-family: "Assistant", sans-serif;
  font-size: 0.8em;
  margin-bottom: 10px;
}

.post h1,
.post h2,
.post p {
  font-family: "Assistant", sans-serif;
  margin-bottom: 10px;
}
.post p b {
  color: rgb(31, 31, 31);
}

.post h1 {
  font-size: 2.2em;
}

.post h2 {
  margin-top: 30px;
  font-size: 1.5em;
}

.post p {
  margin-bottom: 30px;
}

.img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.post img {
  object-fit: cover;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  transition: transform 0.3s;
}

.post img:hover {
  transform: scale(1.01);
}

.post p span {
  display: block;
  margin: 10px 0;
}

@media (max-width: 1300px) {
  .main-section-container {
    grid-template-columns: 1fr 6fr 1fr;
    padding: 0 10px;
  }

  .banner-inner,
  .spacer {
    width: 82vw;
  }
}

@media (max-width: 1100px) {
  .main-section-container {
    grid-template-columns: 1fr 14fr 1fr;
  }

  .banner-inner,
  .spacer {
    width: 85vw;
  }
}

@media (max-width: 900px) {
  .banner-inner,
  .spacer {
    width: 92vw;
  }

  .main-section p {
    font-size: 1em;
  }
}

@media (max-width: 600px) {
  .post h1 {
    font-size: 1.7em;
  }

  .post h2 {
    font-size: 1em;
  }

  .post p {
    font-size: 0.9em;
  }
}

@media (max-width: 400px) {
  .post h1 {
    font-size: 1.5em;
  }

  .post h2 {
    font-size: 0.9em;
  }

  .post p {
    font-size: 0.8em;
  }
}
