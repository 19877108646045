.auth {
  height: 100%;
  margin: 20px 0;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-items: center;
}

@media (max-width: 900px) {
  .auth {
    grid-template-columns: 1fr;
  }
}
