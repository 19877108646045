.main-product-section {
  display: flex;
  flex-flow: column;
}

.product-container {
  display: grid;
  /* grid-template-columns: 1fr 5fr 5fr 1fr; */
  grid-template-columns: 3fr 2fr;
}

.product-image {
  width: 100%;
  height: 130%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  background-position: top;
  min-width: 200px;
  grid-column-start: 2;
}

.spacer {
  height: 1px;
  background-color: #dbdbdb;
  margin: 6px 0;
}

.product-specs h2 {
  color: #3c6373;
}

.mobile-title {
  color: #3c6373;
  align-self: center;
  font-size: 1.9em;
  margin-bottom: 10px;
}

.subtitle {
  display: flex;
  justify-content: space-between;
}

.product-specs h3 {
  margin: 10px 0;
  font-size: 1.1em;
  font-weight: 400;
}

.product-specs .product-price {
  font-size: 1.3em;
  color: #26a69a;
}

.product-specs h3 span {
  font-weight: 600;
  font-size: 1.1em;
  color: #3c6373;
}

.product-specs h4 {
  margin: 8px 0;
}

.product-details {
  text-align: justify;
  font-size: 0.85em;
  line-height: 1.7em;
}

.stock-container {
  display: flex;
  position: relative;
}

.stock {
  margin: 10px 0;
  font-size: 0.8em;
}

.free-shipping {
  background-color: #e9e9e9;
  font-size: 0.85em;
  font-weight: bold;
  color: rgb(99, 99, 99);
  border-radius: 6px;
  border: 2px solid rgb(173, 173, 173);
  padding: 16px;
  margin-bottom: 16px;
  margin-top: 10px;
}

.add-to-cart {
  margin-top: 10px;
  margin-bottom: 0px;
  width: 100%;
  background-color: #3c6373;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.2em;
  cursor: pointer;
  border-radius: 4px;
}

.add-to-cart:hover:enabled {
  background-color: rgb(76, 131, 153);
}

.cart-warning {
  margin: 6px 0;
  color: red;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
}

.product-specs h3 .save-x {
  margin-left: 8px;
  font-size: 0.74em;
  color: black;
  font-weight: lighter;
}

.small-realstate-item {
  display: none;
}

@media (min-width: 1900px) {
  .product-image {
    height: 35vw;
  }
}

@media (max-width: 1600px) {
  .product-container {
    grid-template-columns: 3fr 2fr;
  }
  .product-image {
    grid-column-start: 1;
    margin: 0 auto;
    width: 90%;
    height: 50vw;
  }
  .product-specs {
    grid-column-start: 2;
    margin-right: 20px;
  }
}

@media (max-width: 900px) {
  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  .product-image {
    height: 90vw;
    background-size: 80vw;
  }

  .product-specs {
    margin: 0 30px;
  }

  .large-realstate-item {
    display: none;
  }

  .small-realstate-item {
    display: unset;
  }
}

@media (max-width: 700px) {
  .mobile-title {
    font-size: 1.8em;
  }
}

@media (max-width: 450px) {
  .mobile-title {
    font-size: 1.6em;
  }
}
