.top-menu {
  height: 100px;
  background-color: black;
}

.top-menu-inner {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 3fr 1fr;
  grid-template-areas: ". logo links .";
  background-color: white;
}

.logo {
  grid-area: logo;
  align-self: center;
}

.top-menu-inner img {
  width: 210px;
}

.top-menu-links {
  grid-area: links;
  display: grid;
  grid-template-columns: 5fr 2fr;
  grid-template-areas: "links usermenu";
  min-width: 550px;
  width: 100%;
}

.navigation-links {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-areas: "link link link link .";
  align-items: center;
  justify-items: center;
}

.navigation-links a {
  color: black;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border-bottom: 3px solid white;
}

.navigation-links a:hover {
  border-bottom: 3px solid #3c6373;
  transition: border-bottom 200ms ease;
}

.active {
  border-bottom: 3px solid #3c6373 !important;
}

.user-menu {
  width: 100%;
  grid-area: usermenu;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
}

.user-menu-logged {
  grid-template-columns: 1fr 1fr 2fr 1fr;
}

.logout {
  transform: scale(0.5);
  cursor: pointer;
}

.user {
  width: 30px;
  cursor: pointer;
}

.cart {
  margin-right: 0;
  transition: 200ms ease;
  transform: scale(1.4);
}

.cart,
.cart-small,
.user,
.logout {
  color: black;
  display: flex;
  justify-items: center;
  align-items: center;
  cursor: pointer;
}

.cart-small {
  justify-self: end;
}

.cart svg g path {
  fill: black !important;
}

.cart-badge {
  position: absolute;
  left: 6px;
  top: -3px;
  height: 11px;
  width: 10px;
  background-color: #3c6373;
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 50%;
  color: white;
  font-size: 0.6em;
  font-weight: 400;
  text-align: center;
}

.small-menu {
  position: fixed;
  top: -1000px;
}

.language {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.language div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

@media (min-width: 1500px) {
  .top-menu-links {
    transition: 200ms ease;
  }
}

@media (min-width: 1700px) {
  .top-menu-inner {
    grid-template-columns: 1fr 1fr 3fr 1fr;
  }
}

@media (max-width: 1200px) {
  .cart {
    transition: 200ms ease;
  }
}

@media (max-width: 900px) {
  .top-menu-inner {
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    grid-template-areas: "logo . user cart burger";
  }

  .top-menu-links,
  .top-menu-links a {
    display: none;
  }

  .logo img {
    margin-left: 10px;
  }

  .cart {
    position: fixed;
    top: -1000px;
  }

  .small-menu {
    position: inherit;
    display: flex;
    align-self: center;
    justify-self: center;
    transform: scale(1);
  }

  .cart-small {
    grid-area: cart;
    color: #3c6373;
  }

  .user-small {
    width: 30px;
    cursor: pointer;
    grid-area: user;
    transform: scale(1.2);
  }

  .user-small svg circle,
  .user-small svg path {
    stroke: #3c6373;
    stroke-width: 2.3;
  }

  .cart-small svg {
    transform: scale(1.7);
  }

  .burger-menu {
    grid-area: burger;
    cursor: pointer;
  }

  .cart-badge {
    left: 6.5px;
    top: -7px;
    width: 12px;
    height: 13px;
    transform: scale(1.3);
  }
}

@media (max-width: 480px) {
  .logo img {
    width: 180px;
  }

  .small-menu {
    transform: scale(0.85);
  }
}

@media (max-width: 360px) {
  .logo img {
    width: 150px;
  }

  .small-menu {
    transform: scale(0.7);
  }
}
