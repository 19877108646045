.product-carousel {
  display: flex;
  flex-direction: column;
  align-self: center;
  grid-column-start: 2;
}
/* 
@media (min-width: 1900px) {
  .product-carousel {
    height: 35vw;
    align-self: unset;
  }
} */

@media (max-width: 1600px) {
  .product-carousel {
    grid-column-start: 1;
    height: 100%;
  }
}

@media (max-width: 900px) {
  .product-carousel-img {
    background-size: 80%;
  }

  .slider {
    width: 100%;
  }

  .slides > div {
    width: 100%;
  }

  .product-carousel-thumb {
    width: 70px;
    margin: 10px;
  }
}

@media (max-width: 800px) {
  .slides > div {
    height: 70vw;
  }
}

@media (max-width: 380px) {
  .slides > div {
    height: 300px;
  }

  .product-carousel-thumb {
    margin: 6px;
    height: 60px;
    width: 60px;
  }
}
