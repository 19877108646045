.banner {
  position: relative;
}

.banner-image {
  width: 100%;
  height: 150px;
  background: url("../assets/img/products-bg.webp") no-repeat center;
  background-size: 100%;
  opacity: 0.5;
  overflow: hidden;
  background-size: cover;
  transition-property: background-position-x;
  transition-duration: 300ms;
}

.banner-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 66vw;
  margin-top: 20px;
  padding-bottom: 12px;
}

.spacer {
  position: absolute;
  width: 66vw;
  height: 6px;
  background-color: black;
  top: 80%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.main-section-container {
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
}

.main-section {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-column-start: 2;
  margin-bottom: 20px;
  padding-top: 20px;
}

.main-section h2 {
  margin-bottom: 20px;
}

.main-section h3 {
  margin: 10px 0;
}

.main-section p {
  margin: 8px 0;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.3em;
  text-align: justify;
}
